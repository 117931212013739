import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil, getObjectFromValue } from '@/shared/utils';

export const getDocumentsList = async () => {
	try {
		const { data } = await apollo.query({
			query: gql`
				query {
					GetDocumentList {
						id
						type
						name
						fileName
						body
						userPassword
						ownerPassword
						xid
						xidGenerated
						ext
						useWatermark
						watermarkPictureXid
						watermarkPictureFileName
						watermarkText
						watermarkWithUser
						publishedOn
						publisherId
						toSign
						toEmail
						toSms
						toWhatsApp
						availableOnline
						publisher {
							name
							firstName
						}
						requireManagerApproval
						deliveryContractId
						firstDeliveryId
						docSignedXid
					}
				}
			`,
			fetchPolicy: 'network-only'
		});

		return data.GetDocumentList;
	} catch (e) {
		console.log({ e });
	}
};

export const getDocument = async (xid) => {
	if (isNil(xid)) return;
	try {
		const list = await getDocumentsList();
		console.log({ list });
		const res = getObjectFromValue(list, 'xid', xid);
		console.log({ res });
	} catch (e) {
		console.log({ e });
	}
};

/**
 * @param {Object} options
 * @return {Boolean}
 */

export const signDocument = async ({ docId, userId, contractId, requesterId, docValidationLevel = null }) => {
	try {
		let MUTATION_SIGN_DOCUMENT = gql`
			mutation ($DocumentId: ID!, $UserId: ID!, $RequesterId: ID, $ContractId: ID!, $DocValLvl: ID) {
				SignDocument(DocumentId: $DocumentId, UserId: $UserId, RequesterId: $RequesterId, ContractId: $ContractId, DocValLvl: $DocValLvl)
			}
		`;
		let variables = {
			DocumentId: parseInt(docId),
			UserId: parseInt(userId),
			ContractId: parseInt(contractId),
			DocValLvl: docValidationLevel
		};

		if (!isNil(requesterId)) {
			MUTATION_SIGN_DOCUMENT = gql`
				mutation ($DocumentId: ID!, $UserId: ID!, $RequesterId: ID, $ContractId: ID!, $DocValLvl: ID) {
					SignDocument(DocumentId: $DocumentId, UserId: $UserId, RequesterId: $RequesterId, ContractId: $ContractId, DocValLvl: $DocValLvl)
				}
			`;

			variables = {
				DocumentId: parseInt(docId),
				UserId: parseInt(userId),
				ContractId: parseInt(contractId),
				DocValLvl: docValidationLevel,
				RequesterId: parseInt(requesterId)
			};
		}

		const { data } = await apollo.mutate({
			mutation: MUTATION_SIGN_DOCUMENT,
			variables
		});

		return data.SignDocument;
	} catch (e) {
		console.log({ e });
	}
};

/**
 * @param {String} xid
 * @return {String}
 */
export const setDocumentToRead = (xid) => console.log({ setDocumentToRead: xid });

/**
 * @param {String} xid
 * @return {String}
 */
export const getDocumentStatus = (xid) => console.log({ getDocumentStatus: xid });

export const getDocumentDeliveries = async (documentId) => {
	try {
		const QUERY_GET_DOCUMENT_DELIVERIES = gql`
			query ($DocumentId: ID!) {
				GetChosenDocumentDeliveryList(DocumentId: $DocumentId) {
					id
					createdAt
					updatedAt
					userId
					contractId
					documentId
					xid
					madeFromXid
					xidSigned
					status
					publishedOn
					receivedOn
					readOn
					signedOn
					currentStep
					senderId
					senderStatus
					senderReadOn
					senderSignedOn
					statusLastModification
					replyXid
					documentName
					user {
						id
						name
						email
						firstName
						departmentName
						functionName
					}
					user {
						id
						name
						firstName
						email
						departmentName
						functionName
					}
					contractStartDate
					contractEndDate
					selected
					toSign
					docDeliveryChild {
						id
						createdAt
						updatedAt
						userId
						contractId
						documentId
						xid
						madeFromXid
						xidSigned
						status
						publishedOn
						receivedOn
						readOn
						signedOn
						currentStep
						senderId
						senderStatus
						senderReadOn
						senderSignedOn
						statusLastModification
						replyXid
						selected
						toSign
						documentName
					}
				}
			}
		`;

		const {
			data: { GetChosenDocumentDeliveryList }
		} = await apollo.query({
			query: QUERY_GET_DOCUMENT_DELIVERIES,
			variables: {
				DocumentId: parseInt(documentId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetChosenDocumentDeliveryList;
	} catch (error) {
		console.log(error);
	}
};

export const getSignUsers = async () => {
	try {
		const QUERY_USERS_FOR_SIGN = gql`
			query {
				GetProjectUsersByDocumentValidation {
					gateKeepers {
						fullName
						id
						name
						firstName
					}
					productionAccountants {
						fullName
						id
						name
						firstName
					}
					upm {
						id
						fullName
						name
						firstName
					}
					lineProducers {
						id
						fullName
						name
						firstName
					}
					studios {
						id
						fullName
						name
						firstName
					}
				}
			}
		`;

		const {
			data: { GetProjectUsersByDocumentValidation }
		} = await apollo.query({
			query: QUERY_USERS_FOR_SIGN,
			fetchPolicy: 'no-cache'
		});

		return GetProjectUsersByDocumentValidation;
	} catch (error) {
		console.log(error);
	}
};

export const getDocumentDeliveryList = async (documentId) => {
	try {
		const GET_DOCUMENT_DELIVERY_LIST = gql`
			query ($DocumentId: ID!) {
				GetDocumentDeliveryList(DocumentId: $DocumentId) {
					user {
						id
						name
						firstName
						email
						departmentName
						functionName
					}
					userId
					contractId
					xid
					status
					senderStatus
					currentStep
					selected
					contractStartDate
					contractEndDate
				}
			}
		`;
		const {
			data: { GetDocumentDeliveryList }
		} = await apollo.query({
			query: GET_DOCUMENT_DELIVERY_LIST,
			variables: {
				DocumentId: parseInt(documentId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetDocumentDeliveryList;
	} catch (err) {
		console.error(err);
	}
};

export const publishDocument = async (documentId) => {
	try {
		const PUBLISH_DOCUMENT = gql`
			mutation ($DocumentId: ID!) {
				PublishDocument(DocumentId: $DocumentId) {
					user {
						id
						name
						firstName
						email
						departmentName
						functionName
					}
					xid
					status
					contractId
					senderStatus
					currentStep
					selected
					contractStartDate
					contractEndDate
				}
			}
		`;
		const {
			data: { PublishDocument }
		} = await apollo.mutate({
			mutation: PUBLISH_DOCUMENT,
			variables: {
				DocumentId: parseInt(documentId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return PublishDocument;
	} catch (err) {
		console.error(err);
	}
};

export const addDeptToDelivery = async (documentId, departmentNumber) => {
	try {
		const DELIVER_DOCUMENT_TO_DEPARTMENT = gql`
			mutation ($DocumentId: ID!, $DepartmentNumber: Int!) {
				DeliverDocumentToDepartment(DocumentId: $DocumentId, DepartmentNumber: $DepartmentNumber) {
					user {
						id
						name
						firstName
						email
						departmentName
						functionName
					}
					xid
					status
					senderStatus
					currentStep
					contractId
					selected
					contractStartDate
					contractEndDate
				}
			}
		`;
		const {
			data: { DeliverDocumentToDepartment }
		} = await apollo.mutate({
			mutation: DELIVER_DOCUMENT_TO_DEPARTMENT,
			variables: {
				DocumentId: parseInt(documentId, 10),
				DepartmentNumber: parseInt(departmentNumber, 10)
			}
		});

		return DeliverDocumentToDepartment;
	} catch (err) {
		console.error(err);
	}
};

export const removeUserFromDelivery = async (documentId, userId, contractId) => {
	try {
		const REMOVE_USER_FROM_DELIVERIES_FOR_DOCUMENT = gql`
			mutation ($DocumentId: ID!, $UserId: ID!, $ContractId: ID!) {
				RemoveUserFromDeliveriesForDocument(DocumentId: $DocumentId, UserId: $UserId, ContractId: $ContractId) {
					user {
						id
						name
						firstName
						email
						departmentName
						functionName
					}
					status
					senderStatus
					contractId
					currentStep
					xid
					selected
					contractStartDate
					contractEndDate
				}
			}
		`;
		const {
			data: { RemoveUserFromDeliveriesForDocument }
		} = await apollo.mutate({
			mutation: REMOVE_USER_FROM_DELIVERIES_FOR_DOCUMENT,
			variables: {
				DocumentId: parseInt(documentId, 10),
				UserId: parseInt(userId, 10),
				ContractId: parseInt(contractId, 10)
			}
		});

		return RemoveUserFromDeliveriesForDocument;
	} catch (err) {
		console.error(err);
	}
};

export const selectUserForDelivery = async (documentId, deliveryDetail) => {
	try {
		const DELIVER_DOCUMENT_TO_USERS = gql`
			mutation ($DocumentId: ID!, $DeliveryDetail: [DocUserContractDeliveryInput]!) {
				DeliverDocumentToUsers(DocumentId: $DocumentId, DeliveryDetail: $DeliveryDetail) {
					user {
						id
						name
						firstName
						email
						departmentName
						functionName
					}
					status
					senderStatus
					contractId
					currentStep
					xid
					selected
					contractStartDate
					contractEndDate
				}
			}
		`;
		const {
			data: { DeliverDocumentToUsers }
		} = await apollo.mutate({
			mutation: DELIVER_DOCUMENT_TO_USERS,
			variables: {
				DocumentId: parseInt(documentId, 10),
				DeliveryDetail: deliveryDetail
			}
		});

		return DeliverDocumentToUsers;
	} catch (err) {
		console.error(err);
	}
};

export const getOodriveSignatureLink = async (documentDeliveryId) => {
	const query = gql`
		query ($documentDeliveryId: ID!) {
			GetOodriveSignatureLink(DocumentDeliveryId: $documentDeliveryId)
		}
	`;

	const {
		data: { GetOodriveSignatureLink }
	} = await apollo.query({
		query,
		variables: {
			documentDeliveryId: +documentDeliveryId
		},
		fetchPolicy: 'no-cache'
	});

	return GetOodriveSignatureLink;
};

export const removeDocumentSigned = async (documentDeliveryId) => {
	const REMOVE_DOCUMENT_SIGNED = gql`
		mutation ($documentDeliveryId: ID!) {
			RemoveDocumentXidSigned(DocumentDeliveryId: $documentDeliveryId)
		}
	`;
	const {
		data: { RemoveDocumentXidSigned }
	} = await apollo.mutate({
		mutation: REMOVE_DOCUMENT_SIGNED,
		variables: {
			documentDeliveryId: +documentDeliveryId
		}
	});

	return RemoveDocumentXidSigned;
};

export const waitForStatusDocumentSigned = async (documentDeliveryId) => {
	const WAITING_FOR_DOCUMENT_STATUS = gql`
		mutation ($documentDeliveryId: ID!) {
			WaitForDocumentStatusChange(DocumentDeliveryId: $documentDeliveryId)
		}
	`;
	const {
		data: { WaitForDocumentStatusChange }
	} = await apollo.mutate({
		mutation: WAITING_FOR_DOCUMENT_STATUS,
		variables: {
			documentDeliveryId: +documentDeliveryId
		}
	});

	return WaitForDocumentStatusChange;
};
